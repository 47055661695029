<template>
    <div v-if="show">
        <div class="card" v-show="operation !== 'detail'">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template v-if="operation === null">{{$t('title.reexportOrders')}}</template>
                                    <template v-else>{{ $t(operationTitle) }}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <div v-if="operation === null">
                                <div class="mt-3">
                                    <!-- Using components -->
                                    <b-input-group class="mt-3">
                                        <b-form-input type="search" class="form-control form-control-sm"
                                                      :placeholder="$t('input.whatAreYouLookingFor')"
                                                      v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                      variant="outline-info"
                                                      @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('reexportloadsownview')">
                                                <i class="fa fa-filter"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.resetList')"
                                                      variant="outline-info"
                                                      @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                <i class="fa fa-refresh"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                            <div v-else>
                                <b-button variant="warning" size="sm" class="mt-3"
                                          @click="handleOperationClose()"
                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                    <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                </b-button>
                            </div>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div><!-- /.card-header -->
            <div class="card-body">
                <div class="reexport-table" v-show="!operation">
                    <b-table hover responsive
                             ref="table"
                             :busy="listingLoading"
                             :items="dataSource"
                             :fields="columns"
                             :sort-by.sync="sortField"
                             @sort-changed="handleSortChange">
                        <template v-slot:cell(id)="{ item, index}">
                            {{ (pagination.perPage * (pagination.current-1)) + (index + 1) }}
                        </template>
                        <template v-slot:cell(supplier_carrier_id)="record">
                            {{record.item.supplier_carrier ? record.item.supplier_carrier.name : ''}}
                        </template>
                        <template v-slot:cell(supplier_compound_id)="record">
                            {{record.item.supplier_compound ? record.item.supplier_compound.name : ''}}
                        </template>
                        <template v-slot:cell(from_location_id)="{item}">
                            <span v-if="item.reexport_load_orders[0] && item.reexport_load_orders[0].from_location">
                                <print-ad :item="item.reexport_load_orders[0] && item.reexport_load_orders[0].from_location" titled="1"></print-ad>
                            </span>
                        </template>
                        <template v-slot:cell(to_location_id)="{item}">
                            <span v-if="item.type == 1">
                                <span v-if="item.reexport_load_orders[0] && item.reexport_load_orders[0].to_location">
                                    <print-ad :item="item.reexport_load_orders[0] && item.reexport_load_orders[0].to_location" titled="1"></print-ad>
                                </span>
                            </span>
                        </template>
                        <template v-slot:cell(transport_vehicle_id)="record">
                            <span v-if="record.item.transport_vehicle">
                                <span v-if="record.item.transport_vehicle.truck_plate_number"> {{ record.item.transport_vehicle.truck_plate_number }} </span>
                                <span v-if="record.item.transport_vehicle.trailer_plate_number"> | {{ record.item.transport_vehicle.trailer_plate_number }} </span>
                            </span>
                        </template>
                        <template v-slot:cell(driver_id)="record">
                            {{record.item.driver ? record.item.driver.name : ''}}
                        </template>
                        <template v-slot:cell(loading_date)="record">
                            <span v-if="record.item.estimated_loading_time">
                                {{$global.utcDateToLocalDate(record.item.estimated_loading_time) }}
                            </span>
                        </template>
                        <template v-slot:cell(status)="record">
                            <re-status :status="record.item.status"></re-status>
                        </template>
                        <template v-slot:cell(updated_at)="record">
                            {{$global.utcDateToLocalDate(record.item.updated_at) }}
                        </template>
                        <template v-slot:cell(action)="record">
                            <a @click="setOperation('edit', record.item.id)"
                               :title="$t('button.title.editItem')"
                               v-if="$global.hasPermission('reexportloadsownview') && record.item.status < 5" class="ml-1"
                               v-b-tooltip.hover>
                                <i class="fe fe-edit"></i>
                            </a>
                            <a-popconfirm title="Are you sure？" @confirm="handleDeleteClick(record.item.id)"
                                          v-if="$global.hasPermission('reexportloadsdestroy') && record.item.status <= 3">
                                <i slot="icon" class="fe fe-trash"></i>
                                <a class="ml-1" :title="$t('button.title.deleteItem')" v-b-tooltip.hover>
                                    <i class="fe fe-trash"></i>
                                </a>
                            </a-popconfirm>
                            <a @click="setOperation('detail', record.item.id)"
                               :title="$t('button.title.detailItem')" class=" ml-1"
                               v-if="$global.hasPermission('reexportloadsownview')"
                               v-b-tooltip.hover>
                                <i class="icmn-info"></i>
                            </a>
                        </template>
                    </b-table><!-- /.b-table -->

                    <div class="clearfix">
                        <div class="float-left ">
                            <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]" size="sm"></b-form-select>
                        </div>
                        <div class="float-right">
                            <b-pagination
                                    v-model="pagination.current"
                                    :total-rows="pagination.total"
                                    :per-page="pagination.perPage"
                                    :first-text="$t('paginations.first')"
                                    :prev-text="$t('paginations.prev')"
                                    :next-text="$t('paginations.next')"
                                    :last-text="$t('paginations.last')"
                            ></b-pagination>
                        </div><!-- /.pull-right -->
                    </div><!-- /.clearfix -->
                </div><!-- /.reexport-table-->

                <div class="reexport-operation">
                    <div v-if="operation === 'edit'">
                        <form @submit.prevent="handleSubmit" autocomplete="off">
                            <b-row>
                                <b-col cols="12" v-show="global.pendingRequests > 0">
                                    <a-skeleton active :paragraph="{ rows: 5 }"/>
                                </b-col>

                                <template v-show="global.pendingRequests <= 0">

                                    <b-col md="6" lg="6" sm="12">
                                        <b-form-group
                                            :label="$t('input.supplierCarrier')+' *'"
                                            label-for="supplier_id"
                                            :invalid-feedback="formErrors.first('supplier_carrier_id')"
                                        >
                                            <treeselect
                                                :multiple="false"
                                                :disabled="formFields.status >= 4"
                                                :options="dropdowns.supplierCarriers"
                                                placeholder=""
                                                v-model="formFields.supplier_carrier_id"
                                                :class="[{'invalid is-invalid': (formErrors.has('supplier_carrier_id'))}]"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="6" lg="6" sm="12">
                                        <b-form-group
                                            :label="$t('input.toLocation')+' *'"
                                            label-for="location_id"
                                            :invalid-feedback="formErrors.first('location_id')"
                                        >
                                            <treeselect
                                                :multiple="false"
                                                :options="dropdowns.locations"
                                                placeholder=""
                                                v-model="formFields.to_location_id"
                                                :class="[{'invalid is-invalid': (formErrors.has('location_id'))}]"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="form-group">
                                            <div class="clearfix">
                                                <span class="pull-left">
                                                    <label class="d-block">{{$t('input.order')}}</label>
                                                </span><!-- /.pull-left -->
                                                <span class="pull-left ml-2 mb-1">
                                                    <button class="btn btn-warning btn-sm" v-b-tooltip.hover
                                                            :title="$t('button.title.addNewOrder')" type="button"
                                                            @click="handleAddOrderClick">{{$t('button.add')}}</button>
                                                </span><!-- /.pull-left -->
                                            </div><!-- /.clearfix -->
                                            <treeselect
                                                :multiple="false"
                                                :options="dropdowns.orders"
                                                placeholder=""
                                                v-model="selectedOrderId"
                                                :class="[{'invalid is-invalid': (formErrors.has('order_id'))}]" />
                                            <div class="invalid-feedback">{{formErrors.first('order_id')}}</div>
                                        </div>
                                    </b-col>

                                    <b-col md="6" lg="6" sm="12">
                                        <b-row>
                                            <b-col sm="4">
                                                <b-form-group
                                                    :label="$t('input.price')+' *'"
                                                    label-for="price"
                                                    :invalid-feedback="formErrors.first('price')"
                                                >
                                                    <b-form-input
                                                        id="price"
                                                        v-model="formFields.price"
                                                        type="number"
                                                        :state="((formErrors.has('price') ? false : null))"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="4">
                                                <b-form-group
                                                    :label="$t('input.currency')+' *'"
                                                    label-for="currency"
                                                    :invalid-feedback="formErrors.first('currency')"
                                                >
                                                    <treeselect
                                                        :multiple="false"
                                                        :options="dropdowns.currencies"
                                                        placeholder=""
                                                        v-model="formFields.currency"
                                                        :class="[{'invalid is-invalid': (formErrors.has('currency'))}]"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="4" v-if="formFields.status == 3">
                                                <b-form-group
                                                    :label="$t('input.status') + ':'"
                                                    :invalid-feedback="formErrors.first('announce')"
                                                >
                                                    <b-form-checkbox v-model="formFields.announce" name="announce">
                                                        {{$t('title.announce')}}
                                                    </b-form-checkbox>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>

                                    <b-col md="6" lg="6" sm="12">
                                        <b-form-group
                                            :label="$t('input.comment')"
                                            label-for="comment"
                                            :invalid-feedback="formErrors.first('comments')">
                                            <b-textarea
                                                id="comment"
                                                v-model="formFields.comments"
                                                type="text"
                                                :state="((formErrors.has('comments') ? false : null))"
                                                @focus="$event.target.select()"
                                            ></b-textarea>
                                        </b-form-group>
                                    </b-col>

                                    <b-col md="6" lg="6" sm="12" v-if="editLoad && editLoad.supplier_compound">
                                        <b-form-group
                                            :label="$t('input.supplierCompound')"
                                            label-for="supplierCompound"
                                            :invalid-feedback="formErrors.first('supplierCompound')"
                                        >
                                            <b-form-input
                                                disabled
                                                id="supplierCompound"
                                                :value="editLoad.supplier_compound.name"
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>

                                    <b-col sm="12">
                                        <h3 class="text-secondary">{{$t('title.existingOrders')}}</h3>
                                        <hr />

                                        <small class="text-danger" v-if="formErrors.has('orders')">{{formErrors.first('orders')}}</small>
                                        <table class="table table-hover" v-if="editLoad && editLoad.reexport_load_orders">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>{{$t('column.orderId')}}</th>
                                                    <th>{{$t('column.fromLocation')}}</th>
                                                    <th>{{$t('column.importerNumber')}}</th>
                                                    <th>{{$t('column.action')}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(ilo, index) in editLoad.reexport_load_orders" :class="{'bg-light-danger': _.includes(formFields.deleted_orders, ilo.order.id)}">
                                                    <td>{{index + 1}}</td>
                                                    <td>{{ilo.order.order_id}}</td>
                                                    <td><print-ad :item="ilo.from_location"></print-ad></td>
                                                    <td>{{ilo.order.invoice_number_importer}}</td>
                                                    <td>
                                                        <a-popconfirm title="Are you sure？" @confirm="handleDeleteOrderClick(ilo.order.id)"
                                                                      v-if="!_.includes(formFields.deleted_orders, ilo.order.id)">
                                                            <i slot="icon" class="fe fe-trash"></i>
                                                            <a class=" ml-1"
                                                               :title="$t('button.title.deleteItem')"
                                                               v-b-tooltip.hover>
                                                                <i class="fe fe-trash"></i>
                                                            </a>
                                                        </a-popconfirm>
                                                        <template v-if="formFields.new_orders.length <= 0">
                                                            <a class="ml-1"
                                                               v-if="_.includes(formFields.deleted_orders, ilo.order.id) && formFields.new_orders.length <= 0"
                                                               @click="handleUndoDeleteClick(ilo.order.id)"
                                                               :title="$t('button.title.undo')"
                                                               v-b-tooltip.hover>
                                                                <i class="fa fa-undo"></i>
                                                            </a>
                                                        </template>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <h3 class="text-secondary">{{$t('title.newOrders')}}</h3>
                                        <hr/>
                                        <table class="table table-hover" v-if="editLoad && formFields.new_orders.length">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>{{$t('column.orderId')}}</th>
                                                    <th>{{$t('column.fromLocation')}}</th>
                                                    <th>{{$t('column.importerNumber')}}</th>
                                                    <th>{{$t('column.action')}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(ilo, index) in formFields.new_orders">
                                                    <td>{{index + 1}}</td>
                                                    <td>{{ilo.order_id}}</td>
                                                    <td><print-ad :item="ilo.from_location"></print-ad></td>
                                                    <td>{{ilo.order.invoice_number_importer}}</td>
                                                    <td>
                                                        <a-popconfirm title="Are you sure？" @confirm="handleDeleteNewOrderClick(ilo.id)">
                                                            <i slot="icon" class="fe fe-trash"></i>
                                                            <a class=" ml-1"
                                                               :title="$t('button.title.deleteItem')"
                                                               v-b-tooltip.hover>
                                                                <i class="fe fe-trash"></i>
                                                            </a>
                                                        </a-popconfirm>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <hr/>

                                        <b-row class="operation-footer">
                                            <b-col sm="12">
                                                <b-button
                                                    size="sm"
                                                    type="submit"
                                                    variant="primary"
                                                    :disabled="global.pendingRequests > 0"
                                                    v-b-tooltip.hover :title="$t('button.title.save')"
                                                >
                                                    <clip-loader style="display: inline" :loading="true" color="#fff"
                                                                 size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                                                    <i class="fa fa-save mr-1"></i>
                                                    {{$t('button.save')}}
                                                </b-button>
                                                <b-button variant="warning" class="ml-3"
                                                          size="sm" @click="handleOperationClose()"
                                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                                    <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </template>
                            </b-row>
                        </form>
                    </div>
                </div><!--/.reexport-operation-->

                <div class="filter-container">
                    <a-drawer
                        placement="left"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="filters.visible = !filters.visible"
                        :visible="!operation && filters.visible"
                        :zIndex="10"
                        :title="$t('title.advanceFilters')"
                    >
                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.brands')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.brands"
                                            placeholder=""
                                            v-model="filters.brands"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.models')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.models"
                                            placeholder=""
                                            v-model="filters.models"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.loadingPoint')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.fromLocations"
                                            placeholder=""
                                            v-model="filters.fromLocations"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.unloadingPoint')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.toLocations"
                                            placeholder=""
                                            v-model="filters.toLocations"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.supplierCompound')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.supplierCompounds"
                                            placeholder=""
                                            v-model="filters.suppliers"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.status')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.status"
                                            placeholder=""
                                            v-model="filters.status"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.fromAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.from_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.toAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.to_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                    {{$t('button.close')}}
                                </b-button>
                                <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                    {{$t('button.reset')}}
                                </b-button>
                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                    {{$t('button.apply')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.filter-container -->

            </div><!-- /.card-body-->
        </div><!-- /.card -->
        <div v-if="operation === 'detail'">
            <detail :handle-close-operation="handleOperationClose"></detail>
        </div>
    </div>
</template>
<script>
    import ListingMixin from '../../../util/ListingMixin'
    import Error from '../../../util/Error'
    import {mapState} from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import {request} from '../../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'
    import Detail from './detail'
    import qs from "qs"

    const FORM_STATE = {
        supplier_carrier_id: null,
        to_location_id: null,
        id: null,
        comments: null,
        price: 0,
        currency: null,
        reexport_load_orders: [],
        status: 2,
        announce: false,
        _method: 'post',
        deleted_orders: [],
        new_orders: [],
    };

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
        brands: [],
        models: [],
        fromLocations: [],
        toLocations: [],
        suppliers: [],
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.loadNumber'),
            key: 'load_number',
            sortable: true,
        },
        {
            label: self.$t('column.supplierCarrier'),
            key: 'supplier_carrier_id',
            sortable: true,
        },
        {
            label: self.$t('column.supplierCompound'),
            key: 'supplier_compound_id',
            sortable: true,
        },
        {
            label: self.$t('column.loadingPoint'),
            key: 'from_location_id',
            sortable: false,
        },
        {
            label: self.$t('column.unLoadingPoint'),
            key: 'to_location_id',
            sortable: false,
        },
        {
            label: self.$t('column.driver'),
            key: 'driver_id',
            sortable: true,
        },
        {
            label: self.$t('column.transportVehicle'),
            key: 'transport_vehicle_id',
            sortable: true,
        },
        {
            label: self.$t('column.qty'),
            key: 'qty',
            sortable: true,
        },
        {
            label: self.$t('column.loadingDate'),
            key: 'loading_date',
            sortable: false,
        },
        {
            label: self.$t('column.status'),
            key: 'status',
            sortable: true,
        },
        {
            label: self.$t('column.modifiedAt'),
            key: 'updated_at',
            sortable: true,
        },
        (self.$global.hasAnyPermission(['reexportloadsownview'])
            ? {
                label: self.$t('column.action'),
                class: 'text-right w-100px',
                key: 'action',
            } : {}),
    ];

    export default {
        mixins: [ListingMixin],
        components: {
            Treeselect,
            Detail
        },
        data() {
            return {
                operationTitle: 'title.orders',
                formFields: {...FORM_STATE},
                filters: {...FILTER_STATE},
                listUrl: 'reexport/loads/client',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    supplierCompounds:[],
                    supplierCarriers: [],
                    locations: [],
                    orders: [],
                    countries: [],
                    currencies: [],
                    status: [
                        {id: 1, label: this.$t('title.created')},
                        {id: 2, label: this.$t('title.announced')},
                        {id: 3, label: this.$t('title.rejected')},
                        {id: 4, label: this.$t('title.confirmed')},
                        {id: 5, label: this.$t('title.loaded')},
                        {id: 6, label: this.$t('title.inTransit')},
                        {id: 7, label: this.$t('title.unloadRequested')},
                        {id: 8, label: this.$t('title.delivered')},
                    ],
                    fromLocations: [],
                    toLocations: [],
                    brands:[],
                    models:[],
                },
                show: true,
                editLoad: null,
                selectedOrderId: null
            }
        },
        mounted() {
            this.getSupplierCompounds();
            this.getBrands();
            this.getModels();
            this.getLocations();
            this.getSupplierCarriers();
            this.getCurrencies();
            if (this.$route.query && this.$route.query.operation === 'edit' && this.$route.query.oToken) {
                this.handleEditClick(this.$route.query.oToken)
            }

            this.$title = this.$t('topBar.navigations.modules.reexport')
        },
        methods: {
            toggleDetails(row) {
                this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
            },
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = (operation === 'add' ? 'title.addOrder' : 'title.editOrder')
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async handleSubmit() {
                this.formErrors = new Error({})
                if(this.editLoad.reexport_load_orders.length === this.formFields.deleted_orders.length) {
                    const errors = {orders: [ this.$t('validation.minimumOrderRequired') ]}
                    this.formErrors = new Error(errors)
                    return false;
                }

                try {
                    const response = await request({
                        url: 'reexport/loads/client/update',
                        method: 'post',
                        data: {
                            id: this.formFields.id,
                            supplier_carrier_id: this.formFields.supplier_carrier_id,
                            to_location_id: this.formFields.to_location_id,
                            supplier_compound_id: this.formFields.supplier_compound_id,
                            comments: this.formFields.comments,
                            price: this.formFields.price,
                            currency: this.formFields.currency,
                            _method: 'post',
                            deleted_orders: this.formFields.deleted_orders,
                            new_orders: this.formFields.new_orders.map((item) => ({id: item.id})),
                            status: this.formFields.status,
                            announce: this.formFields.announce
                        },
                    })

                    if (this.formFields.id) {
                        this.itemUpdated()
                    }

                    this.handleOperationClose()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            async handleEditClick(id) {
                this.dropdowns.orders = []
                this.formFields.new_orders = []
                this.formFields.deleted_orders = []
                this.operationTitle = this.$t('title.editLoad')
                request({url: `/reexport/loads/client/detail/${id}`})
                .then((response) => {
                    const {data} = response
                    this.editLoad = data
                    this.formFields.id = data.id
                    this.formFields.price = data.price
                    this.formFields.currency = (data.currency || {}).id
                    this.formFields.supplier_carrier_id = (data.supplier_carrier ? data.supplier_carrier.id : null)
                    this.formFields.to_location_id = data.reexport_load_orders[0].to_location.id
                    this.formFields.supplier_compound_id = data.supplier_compound.id
                    this.formFields.limit = data.qty
                    this.formFields.status = data.status
                    this.getOrders(data)
                })
                .catch((error) => {
                    this.handleServerError(error)
                })
            },
            async handleDeleteClick(id) {
                try {
                    const response = await request({
                        method: 'post',
                        url: '/reexport/loads/delete',
                        data: {
                            id: id,
                        },
                    })

                    this.loadList(this.listQueryParams)
                    this.itemDeleted()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.itemDeleteFailsBecsDependency()
                        return false;
                    }

                    this.itemDeleteFails()
                }
            },
            async getOrders(item) {
                try {
                    const params =  {
                        id: item.id,
                        from_location: item.reexport_load_orders[0].from_location.id,
                        supplier_compound: item.supplier_compound.id,
                        brand: item.reexport_load_orders[0].order.brand.id,
                        model: item.reexport_load_orders[0].order.model.id,
                        status: [8],
                    }

                    const {data} = await request({
                        method: "post",
                        url: 'dropdowns/orders/reexport/update',
                        params: params,
                        paramsSerializer: ((params) => qs.stringify(params)),
                    })

                    this.dropdowns.orders = data.map(item => ({
                        id: item.id,
                        label: `${item.order_id} | ${item.vin_number}`,
                        item: item
                    }))
                } catch (e) {

                }
            },
            async getSupplierCompounds() {
                try {
                    const response = await request({
                        url: '/dropdowns/suppliers/compound',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supplierCompounds = data

                } catch (e) {
                    this.dropdowns.supplierCompounds = []
                }
            },
            async getBrands() {
                try {
                    const response = await request({
                        url: '/dropdowns/brands',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.brands = data

                } catch (e) {
                    this.dropdowns.brands = []
                }
            },
            async getModels() {
                try {
                    const response = await request({
                        url: '/dropdowns/models',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.models = data.map(item => ({id: item.id, label: `${item.label}`}))
                } catch (e) {
                    this.dropdowns.models = []
                }
            },
            async getLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.locations = data
                    this.dropdowns.fromLocations = data
                    this.dropdowns.toLocations = data
                } catch (e) {
                    this.dropdowns.locations = []
                    this.dropdowns.fromLocations = []
                    this.dropdowns.toLocations = []
                }
            },
            async getSupplierCarriers() {
                try {
                    const response = await request({
                        url: '/dropdowns/suppliers/carrier',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supplierCarriers = data

                } catch (e) {
                    this.dropdowns.supplierCarriers = []
                }
            },
            async getCurrencies() {
                try {
                    const response = await request({
                        url: '/dropdowns/currencies',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.currencies = data
                } catch (e) {
                    this.dropdowns.currencies = []
                }
            },
            validateOrderError() {
                const errors = {}
                let hasError = false
                if(!this.selectedOrderId) {
                    errors.order_id = [this.$t('validation.required')]
                    hasError = true
                }

                const existingOrders = this.editLoad.reexport_load_orders.length - this.formFields.deleted_orders.length
                if((existingOrders + this.formFields.new_orders.length + 1) > this.editLoad.qty) {
                    errors.order_id = [this.$t('validation.orderLimitExceed')]
                    hasError = true
                }

                if(_.find(this.editLoad.reexport_load_orders, {id: this.selectedOrderId})) {
                    errors.order_id = [this.$t('validation.duplicate')]
                    hasError = true
                }

                this.formErrors = new Error(errors)
                return hasError
            },
            handleAddOrderClick() {
                if(this.validateOrderError())
                    return false

                this.formFields.new_orders.push(_.find(this.dropdowns.orders, {id: this.selectedOrderId}).item)
                this.selectedOrderId = null
            },
            handleDeleteNewOrderClick(orderId) {
                const orders = this.formFields.new_orders
                orders.splice(orderId, 1)
                this.formFields.new_orders = orders
            },
            handleDeleteOrderClick(orderId) {
                this.formFields.deleted_orders.push(orderId)
            },
            handleUndoDeleteClick(orderId) {
                const orders = this.formFields.deleted_orders
                orders.splice(orderId, 1)
                this.formFields.deleted_orders = _.compact(orders)
            },
            hasListAccess() {
                return this.$global.hasPermission('reexportloadsownview')
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.handleResetClick()
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
                this.editLoad = null
                this.selectedOrderId = null
                this.dropdowns.orders = []
                this.formFields.deleted_orders = []
                this.formFields.new_orders = []
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
<style lang="scss">
    .w-80{
        width: 80px;
    }

    .w-220{
        width: 220px;
    }

    .in-center{
        position: relative;
        top: 1px;
        margin-right: 8px;
    }

    .order-select-chbx{
        position: absolute;
        right: -7px;
        top: -7px;
    }

    .add-load-btn{
        position: absolute;
        top: 12px;
        right: 50px;
    }

    @media screen and (max-width: 768px) {
        .ant-drawer-content-wrapper {
            width: 98% !important;
        }
    }

    .min-height-375{
        min-height: 375px;
    }

    .w-100px{
        width: 100px;
    }
</style>
